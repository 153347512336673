import React, {useState} from 'react';
import styles from '../styles/modal.module.scss';
import { FiChevronLeft, FiSearch, FiPlay, FiX } from 'react-icons/fi';

export default function Modal({children, isOpen, close, title}) {

  if(isOpen){
    return (
      <div className={styles.modal} >
        <div className={styles.content} >
          <div style={{marginBottom: '12px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
            <p className={styles.title} >{title}</p>
            <div onClick={()=> close() }  >
              <svg fill="none" stroke="currentColor" className={styles.closeBtn} strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
            </div>
          </div>
          {children}
        </div>
        <div className={styles.bg} onClick={()=> close() } />
      </div>
    )
  }else{
    return (
      null
    )
  } 

}