import React, {useContext, useState, useRef, useEffect} from 'react';
import styles from '../styles/videos.module.scss';
 
import { FiChevronLeft, FiChevronRight, FiPlay, FiImage } from 'react-icons/fi';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Modal from './Modal';
import accordion from './Customaccordion';

 

import { UserContext } from '../Contexts/UserContext';
import imgTest from '../files/img.jpg';
import imgTest_2 from '../files/img_2.jpg';
import imgTest_3 from '../files/img_3.jpg';
import gifTest from '../files/gif-test.gif';
import CustomAccordion from './Customaccordion';

const imgsTest = [
  {id: 1, img: imgTest},
  {id: 2, img: imgTest_2},
  {id: 3, img: imgTest_3},
  {id: 4, img: imgTest_2},
  {id: 5, img: imgTest},
  {id: 6, img: imgTest_2},
  {id: 1, img: imgTest},
  {id: 2, img: imgTest_2},
  {id: 3, img: gifTest},
  {id: 4, img: imgTest_2},
  {id: 5, img: imgTest},
  {id: 6, img: imgTest_2},
]

function PhotoRoomModal({images}){

  const [selectedImgIndex, setSelectedImgIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImgIndex(index === selectedImgIndex ? null : index);
  };
  
  return(
    <div className={styles.photoRoomModal} > 
    {
      images.map((it, index)=>
        <div 
          className={`${styles.photoRoomModalWrapper} ${selectedImgIndex === index ? styles.fullWidth : ''}`}
          key={index}
          onClick={() => handleImageClick(index)}
        >
          <img src={it.url} />
          <div className={styles.photoRoomModalDarkOverlay} >
            <span>title of the image</span>
          </div>
        </div>
      )
    }
    </div>
  )
}

function VideoContainer({item, openVideoModal}){

  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.video} key={item.id.toString()} >
      <div className={styles.thumbnailWrapper} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
        <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
        <div className={styles.videoDarkOverlay} >
          <FiPlay color="#fff" size={36} />
        </div>
      </div>
      <p className={styles.videoTitle} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}

function PhotoModal({img}){
  return(
    <div style={{height: '80vh', width: '100%'}} >
      <img src={img.url} style={{height: '100%', width: '100%', objectFit: "contain", borderRadius: 12}} />
    </div>
  )
}
function TextModal({img}){
  return(
    <div style={{height: '10px', width: '100%'}} >
      <textarea value={img.url} style={{height: '100%', width: '100%', objectFit: "contain", borderRadius: 1}}></textarea>
       
    </div>
  )
}

function PhotoContainer({it, openModal, i18n}){
  return(
    <div 
      className={`${styles.photoRoomModalWrapper}`}
      key={it.id}
      onClick={() => openModal({en_title: it.en_title, ar_title: it.ar_title, url: it.details})}
    >
      <img src={it.url} />
      <div className={styles.photoRoomModalDarkOverlay} >
        <span>{i18n.language === "en" ? it.en_title : it.ar_title}</span>
      </div>
    </div>
  )
}
function TextContainer({it, openModal, i18n}){
  return(
    <div 
      className={`${styles.photoRoomModalWrapper}`}
      key={it.id}
      onClick={() => openModal({en_title: it.en_title, ar_title: it.ar_title, url: it.details})}
    >
      <img src={it.url} />
      <div className={styles.photoRoomModalDarkOverlay} >
        <span>{i18n.language === "en" ? it.en_title : it.ar_title}</span>
      </div>
    </div>
  )
}
export default function Videos() {

  let { cat } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const {apiUrl, siteId} = useContext(UserContext);
  const topRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [videos, setVideos] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [category, setCategory] = useState({});
  const [isPhotoRoomModalOpen, setIsPhotoRoomModalOpen] = useState(false);
  const [isTextRoomModalOpen, setIsTextRoomModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const openVideoModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsVideoModalOpen(true);
    setModalData(item);
  }
  const closeVideoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsVideoModalOpen(false);
    setModalData({});
  }
  const openPhotoRoomModal = (img)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsPhotoRoomModalOpen(true);
    setModalData(img);
  }
  const closePhotoRoomModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsPhotoRoomModalOpen(false);
    setModalData({});
  }
  const openTextRoomModal = (img)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTextRoomModalOpen(true);
    setModalData(img);
  }
  const closeTextRoomModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTextRoomModalOpen(false);
    setModalData({});
  }

  const getCatById = ()=>{
    axios.get(`${apiUrl}/category/${cat}`)
    .then(response => {
      setCategory(response.data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  const getCategoryVideos = async () => {
    let url = `${apiUrl}/filter/`;
    url += `${siteId}`;
    url += `/${cat}`;

    try {
      axios.get(url)
      .then((response) => {
        setVideos(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    } catch (error) {
      console.error(error);
    }
  };

  const getTextsBySiteAndCategory = async () => {
    try {
      const response = await axios.get(`${apiUrl}/texts-byCat`, {
        params: {
          siteId: `${siteId}` ,
          categoryId: cat
        }
      });  
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images', error);
      // Handle the error appropriately
    }
  };
  const getImagesBySiteAndCategory = async () => {
    try {
      const response = await axios.get(`${apiUrl}/images-byCat`, {
        params: {
          siteId: `${siteId}` ,
          categoryId: cat
        }
      });  
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images', error);
      // Handle the error appropriately
    }
  };


  useEffect(()=>{
    window.scrollTo(0, topRef.current.offsetTop);
    getCategoryVideos();
    getCatById();
    getTextsBySiteAndCategory();
    getImagesBySiteAndCategory();
  },[location.pathname]);

  return (
    <div className={styles.container} style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}} ref={topRef} >
      <div className={styles.header} onClick={() => navigate(-1)} >
        {
          i18n.language === 'en'
          ? <FiChevronLeft size={38} color='#fff' className={styles.backBtn} />
          : <FiChevronRight size={38} color='#fff' className={styles.backBtn} />
        }
          <p>{ i18n.language === 'en' ? category.en_title : category.ar_title }</p>
      </div>
      {      <div className={styles.Textwrapper} >
      { Object.keys(category).length > 0 && category.cat_type === "text"
           ?images.length > 0 && images.map((it)=> <CustomAccordion  title={ it.ar_title} key={it.id} item={it.id} ><p>{it.details}</p></CustomAccordion>)
           :''}
      
        </div>
}      
  {
    <div className={styles.videosWrapper} >
    {/* { images.length > 0 && <PhotoRoomWrapper openModal={openPhotoRoomModal} images={images.slice(0, 3)}  />} */}
    { Object.keys(category).length > 0 && category.cat_type === "image" 
      ? images.length > 0 && images.map((it)=> <PhotoContainer key={it.id} it={it} i18n={i18n} openModal={openPhotoRoomModal} /> )
      : videos.length > 0 && videos.map((it)=> <VideoContainer key={it.id} item={it} openVideoModal={openVideoModal} /> ) 
    }
  </div>
  }
      
      <Modal isOpen={isPhotoRoomModalOpen} close={closePhotoRoomModal} title={i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <PhotoModal img={modalData} />
      </Modal>
      <Modal isOpen={isTextRoomModalOpen} close={closeTextRoomModal} title={i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <TextModal img={modalData} />
      </Modal>
      {/* <Modal isOpen={isPhotoRoomModalOpen} close={closePhotoRoomModal} title="Images collection" >
        <PhotoRoomModal images={images} />
      </Modal> */}
      <Modal isOpen={isVideoModalOpen} close={closeVideoModal} title={ i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <div style={{
          width: '100%', 
          height: window.innerWidth < 642 ? window.innerHeight*0.3 : window.innerHeight*0.5
        }} >
          <iframe 
            src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0&fullscreen=1`} 
            title={modalData.en_title}
            style={{ width: '100%', height: '100%', border: 'none' }}
            frameborder="0" 
            allowfullscreen="allowfullscreen" 
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
            >
          </iframe>
        </div>
        {/* <iframe src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0`} width={window.innerWidth*0.6} height={window.innerHeight*0.6} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> */}
      </Modal>
    </div>
  )
}
