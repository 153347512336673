
import React, { useState } from "react";
 

const CustomAccordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? "active" : ""}`}>
      <div className={`accordion-header ${isOpen ? "active" : ""}`} onClick={toggleAccordion}>
        <b>{title}
        <span class={`material-symbols-outlined ${isOpen ? "active" : ""}`}> ..<hr/>
        </span></b>
      </div>

      {isOpen && (
        <div className="accordion-content">
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

export default CustomAccordion;