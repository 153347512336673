import React, { useEffect, useState } from 'react';

import { loginEvent } from '../Contexts/events';

export default function IsLoggedIn() {

  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    const userToken = sessionStorage.getItem('tokenId');
    if (userToken) {
      setAuthState(true);
    }
  }, []);

  useEffect(() => {
    const handleLogin = (state) => {
      setAuthState(state);
    };

    loginEvent.on('loggedIn', handleLogin);

    return () => {
      loginEvent.removeListener('loggedIn', handleLogin);
    };
  }, []);
  
  return authState;

}
