import React,{ createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [apiUrl, setApiUrl] = useState('');
  const [siteId, setSiteId] = useState(0);
  const [authApiUrl, setAuthApiUrl] = useState('');
  const [bearerToken, setBearerToken] = useState('');

  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(config =>{
        setApiUrl(config.apiUrl);
        setSiteId(config.siteId);
        setAuthApiUrl(config.authApiUrl);
        setBearerToken(config.bearerToken);
      });
  }, []);

  const values = {
    direction: 'ltr',
    apiUrl,
    siteId,
    authApiUrl,
    bearerToken
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};